<script>
    import OverlayLink from "@/components/OverlayLink.svelte";

    export let privacyPolicy;
</script>

<p class="privacy-policy">
    Les vår
    <OverlayLink header="Personvern" label="personvernerklæring">
        {@html privacyPolicy}
    </OverlayLink>.
</p>
